import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";

const Calendly = () => {
  const handleBookDemoClick = () => {
    scrollToSection();
    window.open("https://calendly.com/leremitt_/introduction", "_blank");
  };

  const [showButton, setShowButton] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById("LeremittConnect");
      if (section) {
        const { top } = section.getBoundingClientRect();
        setShowButton(top > window.innerHeight);
      } else setShowButton(true);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = () => {
    const section = document.getElementById("LeremittConnect");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    showButton && (
    <div
      id="calendlybtn"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          position: "fixed",
          bottom: "1rem",
          right: "1.3rem",
          zIndex: "1000",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          sx={{
            position: "relative",
            overflow: "hidden",
            transition: "color .5s",
            zIndex: 1,
            fontWeight: "500",
            fontSize: "1.3rem",
            color: "#142d82",
            background: "#04cee5",
            textTransform: "none",
            boxShadow: "none",
            borderRadius: "0",
            "&:hover": {
              backgroundColor: "#04cee5",
              boxShadow: "none",
              color: "#fff",
              "&:before": {
                top: "-30px",
                left: "-30px",
              },
            },
            "&:before": {
              content: '""',
              position: "absolute",
              zIndex: -1,
              background: "#142d82",
              height: "150px",
              width: "260px",
              borderRadius: "50%",
              top: "100%",
              left: "100%",
              transition: "all .7s",
            },
            "&:active:before": {
              background: "#142d82",
              transition: "background 0s",
            },
          }}
          onClick={handleBookDemoClick}
        >
          Book an Appointment
        </Button>
      </Box>
    </div>
    )
  );
};

export default Calendly;
