import React from 'react';
import { Box } from '@mui/material';
import { keyframes } from '@mui/system';

const animateLoaders = keyframes`
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(0.8);
  }
`;

const containerStyle = {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#e6fafc'
};

const cubeStyle = {
    height: '70px',
    width: '70px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '6px'
};

const cubeItemStyle = {
    height: '30px',
    width: '30px',
    // borderRadius: '10px',
    transition: 'all 0.2s ease-in'
};

const cubeXStyle = {
    ...cubeItemStyle,
    backgroundColor: '#142d82',
    animation: `${animateLoaders} 1s infinite`
};

const cubeYStyle = {
    ...cubeItemStyle,
    backgroundColor: '#00b8d4',
    animation: `${animateLoaders} 1s 0.5s infinite`
};

const Loading = () => {
    return (
        <Box sx={containerStyle}>
            <Box sx={cubeStyle}>
                <Box sx={cubeXStyle}></Box>
                <Box sx={cubeYStyle}></Box>
                <Box sx={cubeYStyle}></Box>
                <Box sx={cubeXStyle}></Box>
            </Box>
        </Box>
    );
};

export default Loading;
